<template>
  <main id="ShopConfig">
    <CContainer>
      <div class="mb-5">
        <h3 class="font-weight-bold">
          {{ $t('ShopSetting.PaymentAndOrder') }}
        </h3>
        <hr>
        <CCard>
          <CCardBody class="d-flex justify-content-between">
            <div>
              <h5 class="text-info">
                {{ $t('ShopSetting.AutoCancelOrder') }}
              </h5>
              <div>{{ $t('ShopSetting.AutoCancelOrderDescription') }}</div>
              <div class="mt-3">
                <CSelect description="預設取消時間為一小時。" :label="$t('Payment.PaymentMethods.CREDIT')" :options="ExpiredOptions" v-model="ShopSetting.AutoCancelOrderTime.CREDIT" :value.sync="ShopSetting.AutoCancelOrderTime.CREDIT" :placeholder="$t('Global.PleaseSelect')" />
                <CSelect description="預設取消時間為一小時。" :label="$t('Payment.PaymentMethods.CREDIT_DIVIDE')" :options="ExpiredOptions" v-model="ShopSetting.AutoCancelOrderTime.CREDIT_DIVIDE" :value.sync="ShopSetting.AutoCancelOrderTime.CREDIT_DIVIDE" :placeholder="$t('Global.PleaseSelect')" />
                <CSelect description="預設取消時間為一小時。" :label="$t('Payment.PaymentMethods.CREDIT_PERIOD')" :options="ExpiredOptions" v-model="ShopSetting.AutoCancelOrderTime.CREDIT_PERIOD" :value.sync="ShopSetting.AutoCancelOrderTime.CREDIT_PERIOD" :placeholder="$t('Global.PleaseSelect')" />
                <CSelect description="預設取消時間為一小時。" :label="$t('Payment.PaymentMethods.WEBATM')" :options="ExpiredOptions" v-model="ShopSetting.AutoCancelOrderTime.WEBATM" :value.sync="ShopSetting.AutoCancelOrderTime.WEBATM" :placeholder="$t('Global.PleaseSelect')" />
                <CSelect description="預設取消時間為七天。" :label="$t('Payment.PaymentMethods.CVS')" :options="ExpiredOptions" v-model="ShopSetting.AutoCancelOrderTime.CVS" :value.sync="ShopSetting.AutoCancelOrderTime.CVS" :placeholder="$t('Global.PleaseSelect')" />
                <CSelect description="預設取消時間為七天。" :label="$t('Payment.PaymentMethods.BARCODE')" :options="ExpiredOptions" v-model="ShopSetting.AutoCancelOrderTime.BARCODE" :value.sync="ShopSetting.AutoCancelOrderTime.BARCODE" :placeholder="$t('Global.PleaseSelect')" />
                <CSelect description="預設取消時間為三天。" :label="$t('Payment.PaymentMethods.ATM')" :options="ExpiredOptions" v-model="ShopSetting.AutoCancelOrderTime.ATM" :value.sync="ShopSetting.AutoCancelOrderTime.ATM" :placeholder="$t('Global.PleaseSelect')" />
              </div>
            </div>
            <CSwitch color="success" :checked.sync="ShopSetting.AutoCancelOrder" />
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody class="d-flex justify-content-between">
            <div>
              <h5 class="text-info">
                {{ $t('ShopSetting.AllowReCreateOrder') }}
              </h5>
              <div>{{ $t('ShopSetting.AllowReCreateOrderDescription') }}</div>
            </div>
            <CSwitch color="success" :checked.sync="ShopSetting.AllowReCreateOrder" />
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody class="d-flex justify-content-between">
            <div>
              <h5 class="text-info">
                {{ $t('ShopSetting.CheckoutRedirect') }}
              </h5>
              <div>{{ $t('ShopSetting.CheckoutRedirectDescription') }}</div>
              <div class="mt-4">
                <CSelect :label="$t('ShopSetting.CheckoutRedirectChooseMode')" :options="[{value: 'Default', label: $t('ShopSetting.CheckoutRedirectModeDefault')}, {value: 'A', label: $t('ShopSetting.CheckoutRedirectModeA')}]" v-model="ShopSetting.CheckoutRedirect" :value.sync="ShopSetting.CheckoutRedirect" :placeholder="$t('Global.PleaseSelect')">
                  <template #label>
                    <label class="font-weight-bold">{{ $t('ShopSetting.CheckoutRedirectChooseMode') }}</label>
                  </template>
                </CSelect>
              </div>
            </div>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody class="d-flex justify-content-between">
            <div>
              <h5 class="text-info">
                {{ $t('ShopSetting.AllowChangePayment') }}
              </h5>
              <div>{{ $t('ShopSetting.AllowChangePaymentDescription') }}</div>
            </div>
            <CSwitch color="success" :checked.sync="ShopSetting.AllowChangePayment" />
          </CCardBody>
        </CCard>
      </div>
      <div class="mb-5">
        <h3 class="font-weight-bold">
          {{ $t('ShopSetting.Invoice') }}
        </h3>
        <hr>
        <CCard>
          <CCardBody class="d-flex justify-content-between">
            <div>
              <h5 class="text-info">
                {{ $t('ShopSetting.EnableInvoice') }}
              </h5>
              <div>{{ $t('ShopSetting.EnableInvoiceDescription') }}</div>
              <div class="mt-4" v-if="ShopSetting.EnableInvoice">
                <CSelect :label="$t('EInvoice.Vendor')"
                         :options="EInvoiceVendorOptions"
                         v-model="ShopSetting.EInvoiceService"
                         :value.sync="ShopSetting.EInvoiceService"
                         :placeholder="$t('Global.PleaseSelect')"
                >
                </CSelect>
              </div>
              <template v-if="ShopSetting.EnableInvoice">
                <h5 class="text-info mt-4">
                  {{ $t('ShopSetting.CreateInvoiceConfig') }}
                </h5>
                <div class="mt-4">
                  <CInput :label="$t('ShopSetting.CreateInvoiceConfigInfo.DefaultEmail')" type="text" v-model="ShopSetting.CreateInvoiceDefaultEmail" :description="$t('ShopSetting.CreateInvoiceConfigInfo.DefaultEmailDescription')" />
                  <CInput :label="$t('ShopSetting.CreateInvoiceConfigInfo.DefaultAddress')" type="text" v-model="ShopSetting.CreateInvoiceDefaultAddress" :description="$t('ShopSetting.CreateInvoiceConfigInfo.DefaultAddressDescription')" />
                  <CInput :label="$t('ShopSetting.CreateInvoiceConfigInfo.DefaultItemName')" type="text" v-model="ShopSetting.CreateInvoiceDefaultItemName" :description="$t('ShopSetting.CreateInvoiceConfigInfo.DefaultItemNameDescription')" />
                </div>
                <h5 class="text-info mt-4">
                  {{ $t('ShopSetting.InvoiceTaxation') }}
                </h5>
                <div>{{ $t('ShopSetting.InvoiceTaxationDescription') }}</div>
                <div class="mt-4">
                  <CSelect :label="'台灣地區'"
                           :options="[{value: 'Taxable', label: $t('ShopSetting.InvoiceTaxationOptions.Taxable')}, {value: 'UnTaxable', label: $t('ShopSetting.InvoiceTaxationOptions.UnTaxable')}]"
                           v-model="ShopSetting.InvoiceTaxationTW" :value.sync="ShopSetting.InvoiceTaxationTW"
                           :placeholder="$t('Global.PleaseSelect')"
                           :description="$t('ShopSetting.InvoiceTaxationPlaceholderTW')">
                  </CSelect>
                  <CSelect :label="'海外地區'"
                           :options="[{value: 'Taxable', label: $t('ShopSetting.InvoiceTaxationOptions.Taxable')}, {value: 'UnTaxableCustom', label: $t('ShopSetting.InvoiceTaxationOptions.UnTaxableCustom')}, {value: 'UnTaxableNonCustom', label: $t('ShopSetting.InvoiceTaxationOptions.UnTaxableNonCustom')}]"
                           v-model="ShopSetting.InvoiceTaxationABROAD" :value.sync="ShopSetting.InvoiceTaxationABROAD"
                           :placeholder="$t('Global.PleaseSelect')"
                           :description="$t('ShopSetting.InvoiceTaxationPlaceholderABROAD')">
                  </CSelect>
                </div>
              </template>
            </div>
            <CSwitch color="success" :checked.sync="ShopSetting.EnableInvoice" />
          </CCardBody>
        </CCard>
        <CCard v-if="ShopSetting.EnableInvoice">
          <CCardBody class="d-flex justify-content-between">
            <div>
              <h5 class="text-info">
                {{ $t('ShopSetting.AutoIssueInvoice') }}
              </h5>
              <div>{{ $t('ShopSetting.AutoIssueInvoiceDescription') }}<span v-html="$t('ShopSetting.AutoIssueInvoiceDescription2')" /></div>
              <div class="mt-4">
                <CInput :label="$t('ShopSetting.IssueInvoiceTime')" type="number" v-model="ShopSetting.IssueInvoiceTime" :description="$t('ShopSetting.IssueInvoiceTimeDescription')" :placeholder="$t('ShopSetting.IssueInvoiceTimePlaceholder')" />
              </div>
              <div v-if="ShopSetting.EnableInvoice" class="my-4">
                <label class="font-weight-bold">可自動開立發票的訂單</label>
                <div class="form-inline">
                  <label for="AutoIssueInvoiceEnableTW" class="mr-2">
                    <input type="checkbox" id="AutoIssueInvoiceEnableTW" name="AutoIssueInvoiceEnableTW" value="TW"
                           v-model="ShopSetting.AutoIssueInvoiceOptions"><span class="ml-2">國內(台灣)訂單</span>
                  </label>
                  <label for="AutoIssueInvoiceEnableABROAD" class="mr-2">
                    <input type="checkbox" id="AutoIssueInvoiceEnableABROAD" name="AutoIssueInvoiceEnableABROAD" value="ABROAD"
                           v-model="ShopSetting.AutoIssueInvoiceOptions"><span class="ml-2">海外訂單</span>
                  </label>
                </div>
              </div>
            </div>
            <CSwitch color="success" :checked.sync="ShopSetting.AutoIssueInvoice" />
          </CCardBody>
        </CCard>
      </div>
      <div class="mb-5">
        <h3 class="font-weight-bold">
          {{ $t('ShopSetting.Member') }}
        </h3>
        <hr>
        <CCard>
          <CCardBody class="d-flex justify-content-between">
            <div>
              <h5 class="text-info">
                {{ $t('ShopSetting.CustomerBuy') }}
              </h5>
              <div>{{ $t('ShopSetting.CustomerBuyDescription') }}</div>
            </div>
            <CSwitch color="success" :checked.sync="ShopSetting.CustomerBuy" />
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody class="d-flex justify-content-between">
            <div>
              <h5 class="text-info">
                {{ $t('ShopSetting.Temperature') }}
              </h5>
              <div>{{ $t('ShopSetting.TemperatureDescription') }}</div>
              <div v-if="ShopSetting.Temperature" class="my-4">
                <label class="font-weight-bold">{{ $t('ShopSetting.ChooseTemperature') }}</label>
                <div class="form-inline">
                  <label for="EnableTemperatureOptionsCommon" class="mr-2">
                    <input type="checkbox" id="EnableTemperatureOptionsCommon" name="EnableTemperatureOptionsCommon" value="Common"
                           v-model="ShopSetting.EnableTemperatureOptions" disabled><span class="ml-2">{{ $t('Logistics.TemperatureOptions.Common') }}</span>
                  </label>
                  <label for="EnableTemperatureOptionsFridge" class="mr-2">
                    <input type="checkbox" id="EnableTemperatureOptionsFridge" name="EnableTemperatureOptionsFridge" value="Fridge"
                           v-model="ShopSetting.EnableTemperatureOptions"><span class="ml-2">{{ $t('Logistics.TemperatureOptions.Fridge') }}</span>
                  </label>
                  <label for="EnableTemperatureOptionsFreeze" class="mr-2">
                    <input type="checkbox" id="EnableTemperatureOptionsFreeze" name="EnableTemperatureOptionsFreeze" value="Freeze"
                           v-model="ShopSetting.EnableTemperatureOptions"><span class="ml-2">{{ $t('Logistics.TemperatureOptions.Freeze') }}</span>
                  </label>
                </div>
              </div>
            </div>
            <CSwitch color="success" :checked.sync="ShopSetting.Temperature" />
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody class="d-flex justify-content-between">
            <div>
              <h5 class="text-info">
                {{ $t('ShopSetting.Location') }}
              </h5>
              <div>{{ $t('ShopSetting.LocationDescription') }}</div>
              <div v-if="ShopSetting.Location" class="my-4">
                <label class="font-weight-bold">{{ $t('ShopSetting.ChooseDefaultLocation') }}</label>
                <CSelect class="mb-0" :description="$t('ShopSetting.ChooseDefaultLocationDescription')" :options="CountryOptions" v-model="ShopSetting.LocationDefaultValue" :value.sync="ShopSetting.LocationDefaultValue" :placeholder="$t('Global.PleaseSelect')" />
              </div>
            </div>
            <CSwitch color="success" :checked.sync="ShopSetting.Location" />
          </CCardBody>
        </CCard>
      </div>
      <div>
        <CCard>
          <CCardBody class="d-flex justify-content-between p-3">
            <CButton color="light" class="px-4" @click="Init()">
              {{ $t('Global.Reset') }}
            </CButton>
            <CButton color="success" class="px-4" @click="SetData()">
              {{ $t('Global.Update') }}
            </CButton>
          </CCardBody>
        </CCard>
      </div>
    </CContainer>
    <CElementCover
      v-if="(Loading === true)"
      :opacity="0.8"
    >
      <CSpinner color="primary"/>
    </CElementCover>
  </main>
</template>

<route>
{
  "meta": {
    "label": "購物設定"
  }
}
</route>

<script>
export default {
  name: 'ShopSetting',
  layout: 'manage',
  components: {
  },
  data () {
    return {
      ExpiredOptions: [
        { label: '1小時', value: 3600 },
        { label: '2小時', value: 3600 * 2 },
        { label: '4小時', value: 3600 * 4 },
        { label: '6小時', value: 3600 * 6 },
        { label: '12小時', value: 3600 * 12 },
        { label: '1天', value: 86400 },
        { label: '2天', value: 86400 * 2 },
        { label: '3天', value: 86400 * 3 },
        { label: '4天', value: 86400 * 4 },
        { label: '5天', value: 86400 * 5 },
        { label: '6天', value: 86400 * 6 },
        { label: '7天', value: 86400 * 7 }
      ],
      Loading: false,
      Submit: false,
      ShopSetting: {
        AutoCancelOrderTime: {
          CREDIT: {},
          CREDIT_PERIOD: 3600,
          CREDIT_DIVIDE: 3600,
          WEBATM: 3600,
          CVS: 86400 * 7,
          BARCODE: 86400 * 7,
          ATM: 86400 * 3
        },
        AutoIssueInvoiceOptions: [],
        CheckoutRedirect: 'Default',
        EnableTemperatureOptions: [],
      },
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      }
    }
  },
  computed: {
    CountryOptions () {
      return Object.keys(this.$t('Logistics.CountryOptions')).map(option => {
        return {
          value: option,
          label: this.$t('Logistics.CountryOptions')[option]
        }
      }).filter(option => option.value !== 'TW-MAIN' && option.value !== 'TW-OUTLYING')
    },
    EInvoiceVendorOptions() {
      return [
        {
          value: 'none',
          label: this.$t('EInvoice.EInvoiceSystem.NONE')
        },
        {
          value: 'ecpay-einvoice',
          label: this.$t('EInvoice.EInvoiceSystem.ECPAY-EINVOICE')
        },
        {
          value: 'ezreceipt-einvoice',
          label: this.$t('EInvoice.EInvoiceSystem.EZRECEIPT-EINVOICE')
        },
        {
          value: 'ezreceiptv2-einvoice',
          label: this.$t('EInvoice.EInvoiceSystem.EZRECEIPTV2-EINVOICE')
        },
        {
          value: 'amego-einvoice',
          label: this.$t('EInvoice.EInvoiceSystem.AMEGO-EINVOICE')
        }
      ]
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.$store.dispatch('InnerRequest', {
          url: '/system/get/ShopSetting',
          method: 'post'
        }).then(({data}) => {
          this.ShopSetting = data
          if (typeof data.CheckoutRedirect === 'undefined') {
            this.ShopSetting.CheckoutRedirect = 'Default'
          }
          if (typeof data.AutoIssueInvoiceOptions === 'undefined') {
            this.ShopSetting.AutoIssueInvoiceOptions = []
          }
          if (typeof data.EnableTemperatureOptions === 'undefined') {
            this.ShopSetting.EnableTemperatureOptions = ['Common']
          }
          if (typeof data.AutoCancelOrderTime === 'undefined') {
            this.$set(this.ShopSetting, 'AutoCancelOrderTime', {
              CREDIT: 3600,
              CREDIT_PERIOD: 3600,
              CREDIT_DIVIDE: 3600,
              WEBATM: 3600,
              CVS: 86400 * 7,
              BARCODE: 86400 * 7,
              ATM: 86400 * 3
            })
          }
          return true
        }).catch((err) => {
          throw err
        })
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    SetData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/set/ShopSetting',
        method: 'post',
        data: {
          ShopSetting: this.ShopSetting
        }
      }).then(() => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    }
  }
}
</script>

